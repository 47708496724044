import React, { useState, useEffect, useRef, useCallback } from 'react'
import { Stage, Layer, Text } from 'react-konva'
import { useSelector, useDispatch } from 'react-redux'
import { Redirect, useParams } from 'react-router-dom'
import { Col, CardBody, Card, Row, Button, Modal, ModalBody } from 'reactstrap'
import { withSize } from 'react-sizeme'
import { selectors } from 'features/dashboard'
import { Image as KonvaImage } from 'react-konva'
import saveSvg from '../../../assets/images/illustrations/save-icon.svg'
import {
    getZonesPaths,
    getMapStations,
    getAreaZonesAll,
    getAreaMapSettings,
    updateAreaMapSettings,
} from 'features/dashboard/Map.actionTypes'
import {
    getVehicleCategories,
    sendEstimatedPose,
    updateVehiclesFromWebkook,
} from 'features/dashboard/Vehicle.actionTypes'
import { fileClient } from 'middleware/api'
import {
    createNewTask,
    getActionsPresets,
    getPresetTasksV2,
    getTaskDefinitions,
    getTasksV2,
} from 'features/dashboard/Task.actionTypes'
import IndoorMapPoint from './IndoorMapPoint'
import VehiclePointer from './IndoorVehiclePoint'
import LoadingSpinner from 'components/utils/LoadingSpinner'
import MapObstacle from './MapObstacle'
import RenderZonesPaths from './RenderZonesPaths'
import { MiniMapButtons } from './MiniMapButtons'
import { handleWheel } from './helpers'
import { getDevices, updateDevicesFromWebhook } from 'features/dashboard/RosSetup.actionTypes'
import IndoorMapDevices from './IndoorMapDevices'
import InfoHover from './InfoHover'
import { getMapSource, saveMapSource } from 'db'
import EstimatedPointDot from './EstimatedPointDot'
import EstimatedPoint from './EstimatedPoint'

const IndoorMiniMap = ({
    getRobot,
    actions,
    sidebar,
    icon,
    parentStatus,
    setIcon,
    lastJsonMessage,
    taskHovered,
}) => {
    const { slug } = useParams()
    const dispatch = useDispatch()
    const map = useSelector(selectors.getTeamMap)
    const areaSettings = useSelector(selectors.getTeamMap).areaSettings
    const stations = useSelector(selectors.getMapStations)
    const devices = useSelector(selectors.getDevicess)
    const vehicles = useSelector(selectors.getVehicles)
    const [image, setImage] = useState(null)
    const [status, setStatus] = useState('loading')
    const [selectStation, setSelectedStation] = useState(null)
    const [stageRef, setStageRef] = useState(null)
    const [robotHovered, setRobotHovered] = useState(false)
    const [selectedRobot, setSelectedRobot] = useState(null)
    const [dataHovered, setDataHovered] = useState(null)
    const [sizeText, setSizeText] = useState(14 / stageRef?.scaleX() || 0)
    const [hidePaths, setHidePaths] = useState(!areaSettings?.show_paths || false)
    const [showTagZonesNames, setShowTagZonesNames] = useState(
        areaSettings?.show_tag_zones_names || false
    )
    const [showVehiclesNames, setShowVehiclesNames] = useState(
        areaSettings?.show_vehicles_names || false
    )

    const [messageReceived, setMessageReceived] = useState(true)
    const [estimatedPose, setEstimatedPose] = useState(false)
    const [estimatedPoseArrowStart, setEstimatedPoseArrowStart] = useState(null)
    const [robotSelectedEstPose, setRobotSelectedEstPose] = useState(null)
    const [modal, setModal] = useState(false)
    const toggle = () => setModal(!modal)
    // const [oneIsActive, setOneVehicleActive] = useState(false)

    const [sizes, setSize] = useState({
        width: 1,
        height: 600,
        ratio: 4,
        scale: 0.5,
    })
    const [pos, setPos] = useState('')
    const biggerMapSize = 1000000
    const colors = {}

    const winRef = useRef(null)
    const { uuid } = map.areas
    const { height, width, x, y } = map.aoi
    const { original_height, original_width, resolution, origin_x, origin_y } = map.areaDetails
    const largestPoint = original_height > original_width ? original_height : original_width
    const ratio = width / height

    useEffect(() => {
        setIcon(areaSettings?.show_stations_names)
        setHidePaths(!areaSettings?.show_paths)
        setShowTagZonesNames(areaSettings?.show_tag_zones_names)
        setShowVehiclesNames(areaSettings?.show_vehicles_names)
    }, [areaSettings]) // eslint-disable-line react-hooks/exhaustive-deps

    const updateMapSize = () => {
        if (status !== 'image-error' || status !== 'loading' || winRef.current) {
            setSize({
                width: winRef.current.offsetWidth,
                height: winRef.current.offsetWidth / ratio,
                largestPoint,
                scale: areaSettings.zoom
                    ? areaSettings.zoom
                    : (winRef.current?.offsetWidth - window.innerWidth / 2.5) / width,
                x: areaSettings.center_position
                    ? areaSettings.center_position[0]
                    : -x * sizes?.scale + window.innerWidth / numberSidebar || 0,
                y: areaSettings.center_position
                    ? areaSettings.center_position[1]
                    : -y * sizes?.scale || 0,
            })
        }
    }

    useEffect(() => {
        // Initial size update
        updateMapSize()

        // Handle window resize events
        const handleResize = () => {
            updateMapSize()
        }

        window.addEventListener('resize', handleResize)

        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, [winRef, map, areaSettings]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (parentStatus !== 'inside') return
        const imagePath = map?.areaDetails?.image
        const extracted = imagePath?.match(/\/areas\/(\d+)-/)[1]
        const localStorageKey = `gen-svg-${extracted}-${uuid}`
        setStatus('loading')

        // Helper function to update the image and state
        const setImageAndState = (imageSrc) => {
            const image = new window.Image()
            image.crossOrigin = 'Anonymous'
            image.src = imageSrc
            setImage(image)
            setStatus('loaded')
        }

        // Async function to handle data fetching and caching
        const loadImageData = async () => {
            try {
                // Check if the image is cached in IndexedDB
                const cachedImage = await getMapSource(localStorageKey)

                if (cachedImage) {
                    Promise.all([
                        dispatch(getZonesPaths(uuid)),
                        dispatch(getMapStations({ uuid })),
                        dispatch(getDevices({ slug })),
                        dispatch(getAreaZonesAll(uuid)),
                    ]).then(() => {
                        setImageAndState(cachedImage) // Use cached image if available
                    })
                    return // Exit early if cached image is used
                }

                // Fetch and cache the image if not available locally
                const [imageRes] = await Promise.all([
                    fileClient.get(`/indoors/area/${uuid}/gen.svg`),
                    dispatch(getZonesPaths(uuid)),
                    dispatch(getMapStations({ uuid })),
                    dispatch(getDevices({ slug })),
                    dispatch(getAreaZonesAll(uuid)),
                ])

                const reader = new window.FileReader()
                reader.readAsDataURL(imageRes.data)
                reader.onload = async () => {
                    const imageSrc = reader.result
                    setImageAndState(imageSrc) // Set the fetched image
                    await saveMapSource(localStorageKey, imageSrc) // Cache the image
                }
            } catch (error) {
                console.error('Error loading image data:', error)
                setStatus('image-error')
            }
        }

        loadImageData() // Invoke the async function
    }, [slug, winRef, parentStatus]) // eslint-disable-line react-hooks/exhaustive-deps

    // useEffect(() => {
    //     // Check if at least one vehicle is active
    //     const hasActive = vehicles.teamVehicle.some((veh) => veh.is_online)
    //     setOneVehicleActive(hasActive)
    // }, [vehicles])

    // This calls are set to be called after the map is loaded and mostly are used for task sidebar.
    useEffect(() => {
        if (status === 'loaded') {
            dispatch(getActionsPresets({ slug }))
            dispatch(getTaskDefinitions(slug))
            dispatch(getPresetTasksV2({ slug, page: 1 }))
            dispatch(getVehicleCategories({ slug }))
        }
    }, [status]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (lastJsonMessage && lastJsonMessage.vehicles) {
            dispatch(updateVehiclesFromWebkook(lastJsonMessage.vehicles))
            setMessageReceived(true)
        }
        if (lastJsonMessage && lastJsonMessage.devices) {
            dispatch(updateDevicesFromWebhook(lastJsonMessage.devices))
            setMessageReceived(true)
        }
    }, [dispatch, lastJsonMessage])

    useEffect(() => {
        const interval = setInterval(() => {
            setMessageReceived(false)
        }, 30000)

        return () => clearInterval(interval)
    }, [lastJsonMessage])

    useEffect(() => {
        document.addEventListener('keydown', escFunction, false)

        return () => {
            document.removeEventListener('keydown', escFunction, false)
        }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const escFunction = useCallback((event) => {
        if (event.key === 'Escape') {
            setSelectedRobot(null)
            setEstimatedPose(false)
            setSelectedStation(false)
            setEstimatedPoseArrowStart(null)
            setRobotSelectedEstPose(null)
        }
    }, [])

    const handleDragEnd = (e) => {
        if (e.target._lastPos) {
            const { x, y } = e.target._lastPos

            setSize((prevSize) => ({
                ...prevSize,
                x,
                y,
            }))
        } else {
            const { x, y } = e.target.attrs

            setSize((prevSize) => ({
                ...prevSize,
                x: sizes.x + x,
                y: sizes.y + y,
            }))
        }
    }

    const handleSave = () => {
        const data = {
            center_position: [sizes.x.toFixed(5), sizes.y.toFixed(5)],
            zoom: sizes.scale.toFixed(5),
            width: sizes.width,
        }

        dispatch(updateAreaMapSettings({ uuid, data })).then(({ error }) => {
            if (!error) {
                dispatch(getAreaMapSettings({ uuid }))
                toggle()
            }
        })
    }

    const onStageRefUpdate = (node) => {
        if (node && !stageRef) {
            setStageRef(node)
        }
    }

    const handlePointSubmit = (point) => {
        const data = {
            subtasks: [
                {
                    action: {
                        point,
                        definition: actions
                            .filter((action) => action.slug === 'move_to_point')
                            .map((action) => action.uuid)[0],
                    },
                },
            ],
        }
        dispatch(createNewTask({ slug, data })).then((res) => {
            dispatch(getTasksV2(slug))
        })
    }

    const handleEstimatePose = (position) => {
        setEstimatedPose(false)
        setRobotSelectedEstPose(null)
        setEstimatedPoseArrowStart(null)
        const { x, y, x2, y2 } = position
        var deltaX = x2 - x
        var deltaY = y2 - y
        var yaw = Math.atan2(-deltaY, deltaX).toFixed(2)

        const ox = Math.abs(Math.floor(origin_x / resolution))
        const oy = original_height - Math.abs(Math.floor(origin_y / resolution))
        const Xcords = ((x - ox) * resolution).toFixed(2)
        const YCords = -((y - oy) * resolution).toFixed(2)

        const data = { x: Xcords, y: YCords, yaw }
        dispatch(sendEstimatedPose({ uuid: robotSelectedEstPose, data }))
    }

    const handlePointSubmitSelect = (point, vehiclePoint) => {
        const data = {
            subtasks:
                point.length === 2
                    ? [
                          {
                              action: {
                                  form_values: { x: point[0], y: point[1] },
                                  definition: actions
                                      .filter((action) => action.slug === 'move_to_location')
                                      .map((action) => action.uuid)[0],
                              },
                          },
                      ]
                    : [
                          {
                              action: {
                                  point,
                                  definition: actions
                                      .filter((action) => action.slug === 'move_to_point')
                                      .map((action) => action.uuid)[0],
                              },
                          },
                      ],
            vehicle_uuid: vehiclePoint,
        }
        return dispatch(createNewTask({ slug, data })).then((res) => {
            dispatch(getTasksV2(slug))
            setSelectedStation(null)
            setSelectedRobot(null)
        })
    }

    const handleMouse = (e) => {
        const stage = stageRef
        setSizeText(14 / stage?.scaleX())
        const mousePointTo = getCurrentMousePointer(stage)
        const pos = Object.values(mousePointTo).map((position) => Math.floor(position))
        setPos(pos)
    }

    const getCurrentMousePointer = (stage) => {
        if (!stage.children[0].attrs.x && !stage.children[0].attrs.y) {
            return {
                x: (stage.getPointerPosition()?.x - stage.x()) / stage.scaleX(),
                y: (stage.getPointerPosition()?.y - stage.y()) / stage.scaleY(),
            }
        }
        return {
            x:
                (stage.getPointerPosition()?.x - stage.x()) / stage.scaleX() -
                stage.children[0].attrs.x,
            y:
                (stage.getPointerPosition()?.y - stage.y()) / stage.scaleY() -
                stage.children[0].attrs.y,
        }
    }

    const numberSidebar = sidebar ? 18 : 4.5
    const vehicle = vehicles.teamVehicle?.map((vehicle) => vehicle.is_online)
    return (
        <Row className="m-0 p-0 w-100">
            <Col data-testid="miniMap">
                <div ref={winRef}>
                    {status !== 'image-error' && (
                        <Card className="card-box shadow-none border-0">
                            {status === 'loading' ? (
                                <div style={{ marginTop: '40%' }}>
                                    <LoadingSpinner />
                                </div>
                            ) : (
                                <CardBody className="m-0 p-0 d-flex justify-content-center">
                                    <Stage
                                        md={7}
                                        width={sizes.width || 400}
                                        height={sizes.height || 600}
                                        scaleX={sizes.scale || 1}
                                        scaleY={sizes.scale || 1}
                                        x={sizes.x || 1}
                                        y={sizes.y || 1}
                                        onClick={() => {
                                            if (robotSelectedEstPose) {
                                                setEstimatedPoseArrowStart({ x: pos[0], y: pos[1] })
                                            }

                                            if (estimatedPoseArrowStart) {
                                                const data = {
                                                    ...estimatedPoseArrowStart,
                                                    x2: pos[0],
                                                    y2: pos[1],
                                                }
                                                handleEstimatePose(data)
                                            }
                                        }}
                                        onWheel={(e) => {
                                            const isScrollingDown = e.evt.deltaY > 0
                                            handleWheel(
                                                e,
                                                stageRef,
                                                isScrollingDown,
                                                sizes,
                                                setSize
                                            )
                                            setSizeText(14 / stageRef?.scaleX())
                                        }}
                                        ref={(node) => onStageRefUpdate(node)}
                                        onMouseMove={handleMouse}
                                        onDragMove={handleDragEnd}
                                        onDragEnd={handleDragEnd}
                                    >
                                        <Layer draggable offsetX={0} offsetY={0}>
                                            <KonvaImage
                                                width={original_width}
                                                height={original_height}
                                                image={image}
                                                onClick={() => {
                                                    if (selectedRobot) {
                                                        handlePointSubmitSelect(pos, selectedRobot)
                                                    }
                                                }}
                                            />

                                            {status !== 'loading'
                                                ? map.zones.tag?.map((zone) => (
                                                      <MapObstacle
                                                          cursor={pos}
                                                          stage={stageRef}
                                                          getCurrentMousePointer={
                                                              getCurrentMousePointer
                                                          }
                                                          width={width}
                                                          key={zone?.uuid}
                                                          zone={zone}
                                                          size={sizeText}
                                                          showTagZonesNames={showTagZonesNames}
                                                      />
                                                  ))
                                                : null}

                                            {status !== 'loading'
                                                ? map.zones.charging?.map((zone) => (
                                                      <MapObstacle
                                                          cursor={pos}
                                                          stage={stageRef}
                                                          getCurrentMousePointer={
                                                              getCurrentMousePointer
                                                          }
                                                          width={width}
                                                          key={zone?.uuid}
                                                          zone={zone}
                                                      />
                                                  ))
                                                : null}
                                            {status !== 'loading'
                                                ? map.zones.resting?.map((zone) => (
                                                      <MapObstacle
                                                          cursor={pos}
                                                          stage={stageRef}
                                                          getCurrentMousePointer={
                                                              getCurrentMousePointer
                                                          }
                                                          width={width}
                                                          key={zone?.uuid}
                                                          zone={zone}
                                                      />
                                                  ))
                                                : null}
                                            {status === 'loaded' &&
                                                !hidePaths &&
                                                map.zonesPaths.map((path) => (
                                                    <RenderZonesPaths
                                                        key={path?.uuid}
                                                        path={path}
                                                        scale={sizes.scale}
                                                        slug={slug}
                                                        onlyView={true}
                                                        aoi={map.aoi}
                                                    />
                                                ))}
                                            {status !== 'loading'
                                                ? map.zones.action?.map((zone) => (
                                                      <MapObstacle
                                                          cursor={pos}
                                                          stage={stageRef}
                                                          getCurrentMousePointer={
                                                              getCurrentMousePointer
                                                          }
                                                          width={width}
                                                          key={zone.uuid}
                                                          zone={zone}
                                                      />
                                                  ))
                                                : null}
                                            {status !== 'loading'
                                                ? map.zones.capacity?.map((zone) => (
                                                      <MapObstacle
                                                          cursor={pos}
                                                          stage={stageRef}
                                                          getCurrentMousePointer={
                                                              getCurrentMousePointer
                                                          }
                                                          width={width}
                                                          key={zone.uuid}
                                                          zone={zone}
                                                      />
                                                  ))
                                                : null}
                                            {status !== 'loading'
                                                ? map.zones.speed_limit?.map((zone) => (
                                                      <MapObstacle
                                                          cursor={pos}
                                                          stage={stageRef}
                                                          getCurrentMousePointer={
                                                              getCurrentMousePointer
                                                          }
                                                          width={width}
                                                          key={zone.uuid}
                                                          zone={zone}
                                                      />
                                                  ))
                                                : null}

                                            {devices &&
                                                status === 'loaded' &&
                                                devices.map((device) => (
                                                    <IndoorMapDevices
                                                        key={device?.uuid}
                                                        setDataHovered={setDataHovered}
                                                        device={device}
                                                        aoi={map.aoi}
                                                    />
                                                ))}

                                            {status === 'loaded' &&
                                                stations.map((point) => (
                                                    <IndoorMapPoint
                                                        slug={slug}
                                                        estimatedPose={estimatedPose}
                                                        icon={icon}
                                                        aoi={map.aoi}
                                                        draggable={false}
                                                        point={point}
                                                        key={point?.uuid}
                                                        sizeText={sizeText}
                                                        setDataHovered={setDataHovered}
                                                        handleSubmit={handlePointSubmit}
                                                        largestPoint={sizes.largestPoint}
                                                        selectedRobot={selectedRobot}
                                                        setSelectedStation={setSelectedStation}
                                                        handlePointSubmitSelect={
                                                            handlePointSubmitSelect
                                                        }
                                                    />
                                                ))}

                                            {status !== 'loading'
                                                ? map.zones.forbidden?.map((zone) => (
                                                      <MapObstacle
                                                          cursor={pos}
                                                          stage={stageRef}
                                                          getCurrentMousePointer={
                                                              getCurrentMousePointer
                                                          }
                                                          width={width}
                                                          key={zone?.uuid}
                                                          zone={zone}
                                                      />
                                                  ))
                                                : null}

                                            {messageReceived &&
                                                vehicles.wbvehicles?.map(
                                                    ({ details, ...vehicle }) => (
                                                        <VehiclePointer
                                                            key={vehicle?.uuid}
                                                            stage={stageRef}
                                                            estimatedPose={estimatedPose}
                                                            setRobotSelectedEstPose={
                                                                setRobotSelectedEstPose
                                                            }
                                                            setDataHovered={setDataHovered}
                                                            fill={colors[vehicle?.uuid]}
                                                            selectedRobot={selectedRobot}
                                                            setSelectedRobot={setSelectedRobot}
                                                            robotSelectedEstPose={
                                                                robotSelectedEstPose
                                                            }
                                                            details={details}
                                                            vehicle={vehicle}
                                                            sizeText={sizeText}
                                                            selectStation={selectStation}
                                                            getRobot={getRobot}
                                                            biggerMapSize={biggerMapSize}
                                                            taskHovered={taskHovered}
                                                            setRobotHovered={setRobotHovered}
                                                            handlePointSubmitSelect={
                                                                handlePointSubmitSelect
                                                            }
                                                            showVehiclesNames={showVehiclesNames}
                                                        />
                                                    )
                                                )}
                                            {selectStation && !robotHovered && (
                                                <Text
                                                    x={pos[0]}
                                                    y={pos[1] / 1.2}
                                                    align={'center'}
                                                    text="Pick a robot"
                                                    fontSize={sizeText}
                                                    fontStyle="bold"
                                                    stroke="#670d95"
                                                    strokeWidth={0.1}
                                                />
                                            )}
                                            {selectedRobot && !robotHovered && !estimatedPose && (
                                                <Text
                                                    x={pos[0]}
                                                    y={pos[1] / 1.2}
                                                    align={'center'}
                                                    text={`Click anywhere on the map to send the robot \n or press ESC to cancel`}
                                                    fontSize={sizeText}
                                                    fontStyle="bold"
                                                    stroke="#670d95"
                                                    strokeWidth={0.1}
                                                />
                                            )}

                                            {estimatedPose &&
                                                !robotHovered &&
                                                !robotSelectedEstPose && (
                                                    <Text
                                                        x={pos[0]}
                                                        y={pos[1] / 1.2}
                                                        align={'center'}
                                                        text="Select the robot for the estimated pose"
                                                        fontSize={sizeText}
                                                        fontStyle="bold"
                                                        stroke="#670d95"
                                                        strokeWidth={0.1}
                                                    />
                                                )}

                                            {robotSelectedEstPose && !estimatedPoseArrowStart && (
                                                <EstimatedPointDot x={pos[0]} y={pos[1]} />
                                            )}

                                            {estimatedPoseArrowStart && (
                                                <EstimatedPoint
                                                    currentPos={[
                                                        estimatedPoseArrowStart.x,
                                                        estimatedPoseArrowStart.y,
                                                        ...pos,
                                                    ]}
                                                    x={estimatedPoseArrowStart.x}
                                                    y={estimatedPoseArrowStart.y}
                                                />
                                            )}
                                        </Layer>
                                    </Stage>
                                    {status !== 'loading' && (
                                        <MiniMapButtons
                                            areaUuid={uuid}
                                            toggle={toggle}
                                            icon={icon}
                                            setIcon={setIcon}
                                            hidePaths={hidePaths}
                                            setHidePaths={setHidePaths}
                                            setEstimatedPose={setEstimatedPose}
                                            sizes={sizes}
                                            setSize={setSize}
                                            vehicle={vehicle}
                                            sidebar={sidebar}
                                            stageRef={stageRef}
                                            setSizeText={setSizeText}
                                            showTagZonesNames={showTagZonesNames}
                                            setShowTagZonesNames={setShowTagZonesNames}
                                            showVehiclesNames={showVehiclesNames}
                                            setShowVehiclesNames={setShowVehiclesNames}
                                        />
                                    )}
                                </CardBody>
                            )}
                        </Card>
                    )}
                </div>

                <Modal isOpen={modal} toggle={toggle} centered className="new-modals">
                    <ModalBody>
                        <div
                            className="w-100 d-flex justify-content-between"
                            style={{ marginBottom: '24px' }}
                        >
                            <img alt="save" width="48px" height="48px" src={saveSvg}></img>
                            <img
                                src="/svgs/close-icon/x-dark-default.svg"
                                alt="obstacle-icon"
                                width="24px"
                                height="24px"
                                style={{ cursor: 'pointer' }}
                                onClick={toggle}
                            />
                        </div>
                        Are you sure you want to save this display preferences? This will be default
                        view for this map.
                        <div
                            style={{ marginTop: '24px' }}
                            className={`d-flex w-100 justify-content-between align-items-center`}
                        >
                            <Button
                                className="cancel-btn-modal modals-new-btns w-50 mr-2"
                                onClick={toggle}
                            >
                                No
                            </Button>
                            <Button
                                className="save-btn-modal modals-new-btns w-50"
                                onClick={handleSave}
                            >
                                Yes
                            </Button>
                        </div>
                    </ModalBody>
                </Modal>

                <InfoHover dataHovered={dataHovered} />

                {status === 'image-error' && <Redirect to={`/dashboard/${slug}/maps/upload/`} />}
            </Col>
        </Row>
    )
}

export default withSize({ monitorHeight: true })(IndoorMiniMap)
